import { useMemo } from 'react';
import { GetParams } from '@constants/routes';
import { useTranslation } from 'react-i18next';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

export function useFormLabels(): Record<string, string> {
    const { t } = useTranslation();

    return {
        email: t('labels.email', 'Email'),
        first_name: t('labels.firstName', 'First name'),
        last_name: t('labels.lastName', 'Last name'),
        phone: t('labels.phone', 'Phone'),
        send_notification: t('labels.send_notification', 'Send registration e-mail'),
    };
}

export function useClients() {
    const [query, setQuery] = useQueryParams({
        [GetParams.PaginationPage]: withDefault(NumberParam, 1),
        [GetParams.Search]: withDefault(StringParam, ''),
        [GetParams.Ordering]: withDefault(StringParam, ''),
    });

    const { ordering, search, page } = query;

    const params = useMemo(() => {
        return {
            ordering,
            search,
            page,
        };
    }, [ordering, search, page]);

    return { query, setQuery, params };
}
