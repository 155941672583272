import cn from 'classnames';
import { Typography } from '@react-md/typography';
import { ClientRepresentationSerializer } from '@types';

import styles from './client-selection.module.scss';

interface ClientRowProps {
    client: ClientRepresentationSerializer;
    clientId: number | undefined;
    setClient: (clientId: number) => void;
}

export const ClientRow = ({ client, clientId, setClient }: ClientRowProps) => {
    const { id, email, full_name, phone_number, company_name, orders_count, models_count, last_time_uploaded } = client;

    return (
        <tr className={cn(styles.row, { [styles.current]: id === clientId })} onClick={() => setClient(id!)}>
            <td className={styles.cell}>
                <Typography type="body-2" component="p" className={styles.value}>
                    {email}
                </Typography>
            </td>
            <td className={styles.cell}>
                <Typography type="body-2" component="p" className={styles.value}>
                    {full_name}
                </Typography>
            </td>
            <td className={styles.cell}>
                <Typography type="body-2" component="p" className={styles.value}>
                    {company_name}
                </Typography>
            </td>
            <td className={styles.cell}>
                <Typography type="body-2" component="p" className={styles.value}>
                    {phone_number}
                </Typography>
            </td>
            <td className={styles.cell}>
                <Typography type="body-2" component="p" className={styles.value}>
                    {orders_count}
                </Typography>
            </td>
            <td className={styles.cell}>
                <Typography type="body-2" component="p" className={styles.value}>
                    {models_count}
                </Typography>
            </td>
            <td className={styles.cell}>
                <Typography type="body-2" component="p" className={cn(styles.value, 'rmd-typography--right')}>
                    {last_time_uploaded}
                </Typography>
            </td>
        </tr>
    );
};
