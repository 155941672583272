import cn from 'classnames';
import { forwardRef, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useNavigate, To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider } from '@react-md/divider';
import { Typography } from '@react-md/typography';
import { Pagination } from '@components/pagination';
import { PreloaderBox } from '@components/preloader';
import { ClientRepresentationSerializer, InvalidateLevel } from '@types';
import { appActions } from '@modules/app';
import { clientActions, selectClientId } from '@modules/client';
import {
    clientsActions,
    selectClientsResults,
    selectClientsTotalCount,
    selectIsLoadingClients,
} from '@modules/clients';
import { ClientRow } from './client-row';
import { ClientsPageSize } from './constants';
import { ClientSortableHeader } from './client-sortable-header';
import { useClients } from './hooks';

import styles from './client-selection.module.scss';

interface ClientSelectionProps {
    to: To;
}

export default forwardRef<HTMLDivElement, ClientSelectionProps>(function ClientSelection({ to, ...props }, ref) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const data = useAppSelector(selectClientsResults);
    const totalCount = useAppSelector(selectClientsTotalCount);
    const isLoading = useAppSelector(selectIsLoadingClients);
    const storeClientId = useAppSelector(selectClientId);
    const { params } = useClients();

    const { t } = useTranslation();

    useEffect(() => {
        dispatch(clientsActions.load(params));
    }, [dispatch, params]);

    const setClient = useCallback(
        (clientId: number) => {
            if (clientId !== storeClientId) {
                dispatch(
                    appActions.invalidateStore({
                        purge: InvalidateLevel.Order,
                    }),
                );
                dispatch(clientActions.setClientId(clientId));
                dispatch(clientActions.load(clientId));
            }

            navigate(to);
        },
        [dispatch, navigate, to, storeClientId],
    );

    return (
        <div {...props} ref={ref} className={cn('small-margin-top', styles.box)}>
            <PreloaderBox show={isLoading}>
                {data.length ? (
                    <div className={cn('overflow-x-auto', styles.table)}>
                        <table>
                            <thead className={styles.head}>
                                <tr>
                                    <td className={cn(styles.cell, 'micro-padding-bottom')}>
                                        <ClientSortableHeader label={t('labels.email', 'Email')} value="email" />
                                    </td>
                                    <td className={cn(styles.cell, 'micro-padding-bottom')}>
                                        <ClientSortableHeader
                                            label={t('labels.customer', 'Customer')}
                                            value="full_name"
                                        />
                                    </td>
                                    <td className={cn(styles.cell, 'micro-padding-bottom')}>
                                        <ClientSortableHeader
                                            label={t('labels.company', 'Company')}
                                            value="company_name"
                                        />
                                    </td>
                                    <td className={cn(styles.cell, 'micro-padding-bottom')}>
                                        <ClientSortableHeader label={t('labels.phone', 'Phone')} value="phone_number" />
                                    </td>
                                    <td className={cn(styles.cell, 'micro-padding-bottom')}>
                                        <ClientSortableHeader
                                            label={t('titles.orders', 'Orders')}
                                            value="orders_count"
                                        />
                                    </td>
                                    <td className={cn(styles.cell, 'micro-padding-bottom')}>
                                        <ClientSortableHeader
                                            label={t('titles.models', 'Models')}
                                            value="models_count"
                                        />
                                    </td>
                                    <td className={cn(styles.cell, 'micro-padding-bottom', 'rmd-typography--right')}>
                                        <ClientSortableHeader
                                            label={t('labels.lastTimeActive', 'Last time active')}
                                            value="last_time_uploaded"
                                        />
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                {data.map((client: ClientRepresentationSerializer) => {
                                    return (
                                        <ClientRow
                                            key={client.id}
                                            client={client}
                                            clientId={storeClientId}
                                            setClient={setClient}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <Typography type="body-1" component="div" className="margin-top">
                        {t('messages.noMatchingResults', 'No matching results')}
                    </Typography>
                )}
            </PreloaderBox>

            {totalCount > ClientsPageSize && (
                <div className={styles.bottom}>
                    <Divider className={cn('rmd-divider-no-gutters', styles.divider)} />
                    <Pagination totalCount={totalCount} pageSize={ClientsPageSize} />
                </div>
            )}
        </div>
    );
});
