import { useCallback } from 'react';
import { SearchField } from '@components/search-field';
import { getNodeIdComposer } from '@utils';
import { FiltersId } from './constants';
import { useClients } from './hooks';

const _id = getNodeIdComposer(FiltersId);

export const ClientsSearch = () => {
    const { query, setQuery } = useClients();

    const handleFinalizeSearch = useCallback(
        search => {
            setQuery({
                ...query,
                search,
                page: 1,
            });
        },
        [query, setQuery],
    );

    return (
        <SearchField
            id={_id('search', 'field')}
            value={query.search}
            onFinalize={handleFinalizeSearch}
            style={{ marginTop: '20px' }}
        />
    );
};
