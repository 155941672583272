import cn from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, To } from 'react-router-dom';
import { UseFormReturn, SubmitHandler, FieldPath } from 'react-hook-form';
import { Divider } from '@react-md/divider';
import { TextField, Checkbox } from '@react-md/form';
import { FormField, NonFieldErrors, NonFieldErrorsKey } from '@components/form-field';
import { FeatureContent } from '@components/feature-content';
import { SubmitButton } from '@components/submit-button';
import { CreateClientForm } from '@types';
import { clientActions, selectIsClientCreating } from '@modules/client';
import { getNodeIdComposer, setApiErrors } from '@utils';
import { useFormLabels } from './hooks';
import { FormId, RootPrefix } from './constants';

import styles from './client-form.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'create_client_tab');

interface ClientFormProps extends HTMLAttributes<HTMLDivElement> {
    form: UseFormReturn<CreateClientForm>;
    to: To;
}

export default forwardRef<HTMLDivElement, ClientFormProps>(function ClientForm({ form, to, ...props }, ref) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isCreating = useAppSelector(selectIsClientCreating);

    const {
        control,
        watch,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
        clearErrors,
    } = form;

    const onSubmit: SubmitHandler<CreateClientForm> = form => {
        dispatch(
            clientActions.create({
                form,
                onSuccess: () => {
                    reset();
                    navigate(to);
                },
                onError: errors => {
                    setApiErrors(setError, errors as Record<FieldPath<CreateClientForm>, string>);
                },
            }),
        );
    };

    const [sendNotification] = watch(['send_notification']);

    const { t } = useTranslation();
    const labels = useFormLabels();

    return (
        <div {...props} ref={ref}>
            <form
                id={FormId}
                className={cn('fieldset-grid', 'fieldset-grid--columns', styles.grid)}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormField
                    prefix={FormId}
                    name="first_name"
                    control={control}
                    component={TextField}
                    label={labels.first_name}
                />
                <FormField
                    prefix={FormId}
                    name="last_name"
                    control={control}
                    component={TextField}
                    label={labels.last_name}
                />
                <FormField prefix={FormId} name="phone" control={control} component={TextField} label={labels.phone} />
                <FormField
                    prefix={FormId}
                    name="email"
                    control={control}
                    component={TextField}
                    label={labels.email}
                    widgetProps={{
                        onChange: () => {
                            clearErrors(NonFieldErrorsKey);
                        },
                    }}
                />

                <NonFieldErrors className={cn('fieldset-grid-cell--full', styles.error)} errors={errors} />

                <FormField
                    control={control}
                    prefix={FormId}
                    name="send_notification"
                    component={Checkbox}
                    className="fieldset-grid-cell--full"
                    widgetProps={{
                        label: labels.send_notification,
                        defaultChecked: sendNotification,
                        disableIconOverlay: true,
                        disableProgrammaticRipple: true,
                        disableRipple: true,
                    }}
                />
            </form>

            <div className={styles.bottom}>
                <Divider className={cn('margin-top', 'rmd-divider-no-gutters')} />

                <SubmitButton form={FormId} prefix={_id()} isSending={isCreating}>
                    <FeatureContent contentKey={_id('submit')} fallback={t('buttons.nextStep', 'Next step')} />
                </SubmitButton>
            </div>
        </div>
    );
});
