import cn from 'classnames';
import { Button, TextIconSpacing } from 'react-md';
import { useClients } from './hooks';
import { Icons } from '@components/icons';

import styles from './client-sortable-header.module.scss';

interface ChangeValueToQueryStringParams {
    queryString: string;
    value: string;
    nextValue: string;
}

function changeValueInQueryString({ queryString, value, nextValue }: ChangeValueToQueryStringParams) {
    const queryStringArr = queryString.split(',');
    const filteredQueryStringArr = queryStringArr.filter(item => item.replace('-', '') !== value);
    if (nextValue) filteredQueryStringArr.push(nextValue);
    return filteredQueryStringArr.filter(Boolean).join(',');
}

interface GetSortingStatusProps {
    queryString: string;
    value: string;
}

function getSortingStatus({ queryString, value }: GetSortingStatusProps) {
    const queryArr = queryString.split(',');
    const prevValue = queryArr.find(filter => filter.replace('-', '') === value) || '';
    const isActive = Boolean(prevValue);
    const isAscending = prevValue === value;
    const isDescending = isActive && !isAscending;

    return {
        value,
        isActive,
        isAscending,
        isDescending,
    };
}

interface GetNextValueProps {
    value: string;
    isAscending: boolean;
    isDescending: boolean;
}

function getNextValue({ value, isAscending, isDescending }: GetNextValueProps) {
    return isAscending ? '' : isDescending ? value : `-${value}`;
}

interface SortableHeaderProps {
    label: string;
    value: string;
}

export function ClientSortableHeader({ label, value }: SortableHeaderProps) {
    const { query, setQuery } = useClients();
    const { isActive, isAscending, isDescending } = getSortingStatus({
        queryString: query.ordering,
        value,
    });
    const nextValue = getNextValue({ value, isAscending, isDescending });

    const handleClick = () => {
        setQuery({
            ...query,
            ordering: changeValueInQueryString({ queryString: query.ordering, value, nextValue }),
            page: 1,
        });
    };

    return (
        <Button className={cn({ [styles.active]: isActive }, styles['sorting-button'])} onClick={handleClick}>
            <TextIconSpacing icon={<Icons.Chevron className={cn({ [styles.ascending]: isAscending })} />} iconAfter>
                {label}
            </TextIconSpacing>
        </Button>
    );
}
